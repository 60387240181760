import { storeToRefs } from 'pinia'
import { useCompaniesStore } from '~/stores/companies'
import { until } from '@vueuse/core'

//redirect-to-last-division

export default defineNuxtRouteMiddleware(async ({ params }) => {
  const { companiesById } = storeToRefs(useCompaniesStore())
  const lastDivision = useCookie('last-division')

  if (lastDivision.value && companiesById.value[lastDivision.value]) {
    return navigateTo(`/division/${lastDivision.value}`)
  }
})
